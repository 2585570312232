import '~common/polyfills';
import riveWasm from '@rive-app/canvas/rive.wasm?url';
import { RuntimeLoader } from '@rive-app/canvas';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import queryString from 'query-string';
import Debug from 'debug';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'vite/modulepreload-polyfill';

import { monkeyPatchReactWarnings } from '~common/reactUtils';

import history, { handleCustomBack, getQueryParamsFromUrl } from 'lib/history';
import { setupBasicNativeAppFunctionality } from 'lib/util';

import '~play-ui/core/init';
import 'lib/lazyload';
import './cypress';
import { IMPORT_URLS } from './env';
import { CURRENT_TENANT_KEY } from 'domains/multiClient/constants';

// set wasm url for rive
RuntimeLoader.setWasmUrl(riveWasm);

const loadDayjsPlugins = () => {
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isoWeek);
  dayjs.extend(utc);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(relativeTime);
};

loadDayjsPlugins();

monkeyPatchReactWarnings();

const debug = Debug('ovosplay:start');
console.info(`Starting ovos play v${import.meta.env.VITE_APP_VERSION} - Build time ${import.meta.env.VITE_BUILD_TIME}`);

[
  'VITE_API_URL',
  'VITE_GRAPHQL_URL',
  'VITE_NETWORK_TEST_URL',
  'VITE_SERVICE_URL',
].forEach(key => {
  /**
   * Backwards compatibility for old multiClient (before 2.51.0 - 2022-10-11)
   *
   * Old URLs have been stored under REACT_APP_* keys in localStorage, but now
   * will be stored in VITE_* keys. This code will migrate the old keys to the
   * new ones.
   */
  const webpackKey = key.replace('VITE', 'REACT_APP');
  const oldWebpackAppUrl = localStorage.getItem(webpackKey);

  const multiClientUrl = localStorage.getItem(key) || oldWebpackAppUrl;

  // remove old "webpack" value from localStorage and set new one for vite build
  if (oldWebpackAppUrl) {
    localStorage.removeItem(webpackKey);

    if (multiClientUrl) {
      localStorage.setItem(key, multiClientUrl);
    }
  }

  const url = multiClientUrl || IMPORT_URLS[key];
  if (multiClientUrl || url && !window[key]) {
    debug(`window[${key}] = ${url} (from ${multiClientUrl ? 'localStorage' : 'env'})`);
    window[key] = url;
  }
});

setupBasicNativeAppFunctionality();

App.addListener('backButton', handleCustomBack);

const startAppPromise = startApp();

App.addListener('appUrlOpen', async data => {
  try {
    // always close eventually opened in app browser when appUrlOpen is triggered (e.g during logout or login flow)
    await Browser.close();
  } catch (err) {
    // unavailable and throws in some browsers
  }

  await startAppPromise;

  const { url } = data;

  const query = getQueryParamsFromUrl(url);

  if (query.impersonate) {
    // while this is handled by AuthProvider in the browser version - the app may receive the appUrlOpen data after AuthProvider
    // initialized, thus not triggering the impersonation
    const login = (await import('./domains/authentication/functions/login')).default;
    await login({ type: 'refreshToken', token: query.impersonate, noRedirect: true });

    // impersonation has been consumed - remove it from the query string
    delete query.impersonate;
  }

  const page = url.split('#')[1];
  const urlQuery = `?${queryString.stringify(query)}`;

  history.push({
    pathname: page.replace(/\?.*/, ''),
    search: urlQuery,
  });
});

async function startApp() {
  debug('Starting App');

  if (window.IS_MULTICLIENT && !localStorage.getItem(CURRENT_TENANT_KEY)) {
    window.location.href = '/multiClient.html';
    return;
  }

  const $root = document.getElementById('root');

  // if App is imported before we analyze and overwrite env variables, .env overwrites dont work
  const { default: App } = await import('./App');
  ReactDOM.render(<App />, $root);
}
